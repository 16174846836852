<template>
  <b-link
    :to="{name: 'apps-notification-list'}"
    class="link"
  >
    <feather-icon
      :badge="$store.getters['notification/notificationsUnreadQty']"
      badge-classes="bg-danger"
      class="text-body"
      icon="BellIcon"
      size="21"
    />
  </b-link>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import {
  pushNotification, webNotification, notificationFiltersSortingAndPagination,
} from '@/views/apps/notification/useNotification'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'

import { watch } from '@vue/composition-api'
import { formatDateTime } from '@/filters/dateTime'

import notificationTone from '@/assets/audio/notification-tone.wav'

export default {
  components: {
    BLink,
    notificationTone,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },

  computed: {
  },
  created() {
    GlobalEventEmitter.$on('newMessageReceived', response => {
      this.fetchFilteredNotifications()
      this.playSound()
    })

    GlobalEventEmitter.$on('userPermissionsUpdated', () => {
      this.fetchFilteredNotifications()
    })
  },
  methods: {
    playSound() {
      return new Audio(this.notificationTone).play()
    },
  },
  setup() {
    const { getMessage } = pushNotification()
    getMessage()

    const {
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
    } = notificationFiltersSortingAndPagination()
    const {
      fetchNotifications,
    } = webNotification()

    // Wrapper Function for `fetchNotifications` which can be triggered initially and upon changes of filters
    const fetchFilteredNotifications = () => {
      const args = {
        page: filters.value.page,
        per_page: filters.value.perPage,
        order: filters.value.order,
        unread: 'unread',
      }

      fetchNotifications(args)
    }

    // fetchFilteredNotifications()

    watch([filters, sortBy], () => {
      fetchFilteredNotifications()
    }, {
      deep: true,
    })

    return {
      fetchFilteredNotifications,
    }
  },
}
</script>

<style scoped>
.link{
  margin: 0 0.5rem;
}
</style>
