export default [
  // {
  //   header: 'Apps & Pages',
  // },
  // {
  //   title: 'Email',
  //   route: 'apps-email',
  //   icon: 'MailIcon',
  // },
  // {
  //   title: 'Chat',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'Todo',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   title: 'Apps Calendar',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },
  // {
  //   title: 'Invoice',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-invoice-list',
  //     },
  //     {
  //       title: 'Preview',
  //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Add',
  //       route: { name: 'apps-invoice-add' },
  //     },
  //   ],
  // },
  // {
  //   title: 'eCommerce',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'Shop',
  //       route: 'apps-e-commerce-shop',
  //     },
  //     {
  //       title: 'Details',
  //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
  //     },
  //     {
  //       title: 'Wishlist',
  //       route: 'apps-e-commerce-wishlist',
  //     },
  //     {
  //       title: 'Checkout',
  //       route: 'apps-e-commerce-checkout',
  //     },
  //   ],
  // },
  // {
  //   title: 'User',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-users-list',
  //     },
  //     {
  //       title: 'View',
  //       route: { name: 'apps-users-view', params: { id: 21 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-users-edit', params: { id: 21 } },
  //     },
  //   ],
  // },
  // {
  //   title: 'Point',
  //   icon: 'MapPinIcon',
  //   resource: 'Point',
  //   action: 'read',
  //   children: [
  //     {
  //       title: 'List Table',
  //       route: 'apps-points-list-table',
  //       resource: 'Point',
  //       action: 'read',
  //     },
  //     {
  //       title: 'List',
  //       route: 'apps-points-list',
  //       resource: 'Point',
  //       action: 'read',
  //     },
  //
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-points-edit', params: { id: 43 } },
  //       resource: 'Point',
  //       action: 'update',
  //     },
  //     {
  //       title: 'Add',
  //       route: 'apps-points-add',
  //       resource: 'Point',
  //       action: 'create',
  //     },
  //   ],
  // },
  // {
  //   title: 'Service Requests',
  //   icon: 'PhoneCallIcon',
  //   resource: 'ServiceRequest',
  //   action: 'read',
  //   children: [
  //     {
  //       title: 'Search Requests',
  //       route: 'apps-service-search-requests',
  //       resource: 'ServiceOffer',
  //       action: 'read',
  //     },
  //     {
  //       title: 'My Requests',
  //       route: 'apps-service-my-requests',
  //       resource: 'ServiceRequest',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Add',
  //       route: 'apps-service-request-add',
  //       resource: 'ServiceRequest',
  //       action: 'create',
  //     },
  //   ],
  // },
  {
    header: 'Promo',
    resource: 'Referral',
    action: 'update',
  },
  {
    title: 'referral.referral_links',
    icon: 'LinkIcon',
    route: 'promo-referral-links',
    resource: 'Referral',
    action: 'update',
  },
  {
    title: 'referral.referrals',
    icon: 'UsersIcon',
    route: 'promo-referrals',
    resource: 'Referral',
    action: 'update',
  },
  {
    title: 'Service Providers',
    icon: 'BriefcaseIcon',
    route: 'apps-user-service-providers-list',
    resource: 'Referral',
    action: 'update',
  },
  {
    header: 'Tools',
    resource: 'ServiceRequest',
    action: 'read',
  },
  {
    title: 'Notifications',
    icon: 'BellIcon',
    route: 'apps-notification-list',
    resource: 'ServiceRequest',
    action: 'read',
  },
  {
    title: 'Calendar',
    icon: 'CalendarIcon',
    route: 'event-calendar',
    resource: 'ServiceRequest',
    action: 'read',
  },
  {
    header: 'Requests',
    resource: 'ServiceRequest',
    action: 'read',
  },
  {
    title: 'Search Requests',
    icon: 'SearchIcon',
    route: 'apps-service-search-requests',
    resource: 'ServiceOffer',
    action: 'read',
  },
  {
    title: 'My Requests',
    icon: 'PhoneCallIcon',
    route: 'apps-service-my-requests',
    resource: 'ServiceRequest',
    action: 'read',
  },
  {
    title: 'Add Request',
    icon: 'PlusCircleIcon',
    route: 'apps-service-request-add-wizard',
    resource: 'ServiceRequest',
    action: 'create',
  },
  {
    title: 'location.locations',
    icon: 'MapPinIcon',
    route: { name: 'apps-location-list' },
    resource: 'ServiceRequest',
    action: 'read',
  },
  {
    header: 'Offers',
    resource: 'ServiceOffer',
    action: 'read',
  },
  {
    title: 'Search Offers',
    icon: 'SearchIcon',
    route: 'apps-service-offer-list',
    resource: 'ServiceRequest',
    action: 'read',
  },
  {
    title: 'My Offers',
    icon: 'BriefcaseIcon',
    route: { name: 'pages-account-setting', query: { tab: 'services' } },
    resource: 'ServiceOffer',
    action: 'read',
  },
  {
    title: 'Add Service Offer',
    icon: 'PlusCircleIcon',
    route: 'apps-service-offer-add-wizard',
    resource: 'ServiceOffer',
    action: 'create',
  },
  {
    header: 'Settings',
    resource: 'Account',
    action: 'update',
  },
  {
    title: 'Account',
    icon: 'UserIcon',
    route: { name: 'pages-profile' },
    resource: 'Account',
    action: 'update',
  },

  // {
  //   title: 'Service Offers',
  //   icon: 'ToolIcon',
  //   resource: 'ServiceOffer',
  //   action: 'read',
  //   children: [
  //     {
  //       title: 'My Offers',
  //       route: 'apps-service-my-offers',
  //       resource: 'ServiceOffer',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Add Offer',
  //       route: 'apps-service-offer-add',
  //       resource: 'ServiceOffer',
  //       action: 'create',
  //     },
  //   ],
  // },
  // {
  //   title: 'Pages',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Authentication',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Login v1',
  //           route: 'auth-login-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Login v2',
  //           route: 'auth-login-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v1',
  //           route: 'auth-register-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v2',
  //           route: 'auth-register-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v1',
  //           route: 'auth-forgot-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v2',
  //           route: 'auth-forgot-password-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v1',
  //           route: 'auth-reset-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v2',
  //           route: 'auth-reset-password-v2',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Account Settings',
  //       route: 'pages-account-setting',
  //     },
  //     {
  //       title: 'Profile',
  //       route: 'pages-profile',
  //     },
  //     {
  //       title: 'Faq',
  //       route: 'pages-faq',
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       route: 'pages-knowledge-base',
  //     },
  //     {
  //       title: 'Pricing',
  //       route: 'pages-pricing',
  //     },
  //     {
  //       title: 'Blog',
  //       children: [
  //         {
  //           title: 'List',
  //           route: 'pages-blog-list',
  //         },
  //         {
  //           title: 'Detail',
  //           route: { name: 'pages-blog-detail', params: { id: 1 } },
  //         },
  //         {
  //           title: 'Edit',
  //           route: { name: 'pages-blog-edit', params: { id: 1 } },
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Mail Templates',
  //       children: [
  //         {
  //           title: 'Welcome',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
  //         },
  //         {
  //           title: 'Reset Password',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
  //         },
  //         {
  //           title: 'Verify Email',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
  //         },
  //         {
  //           title: 'Deactivate Account',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
  //         },
  //         {
  //           title: 'Invoice',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
  //         },
  //         {
  //           title: 'Promotional',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Miscellaneous',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Coming Soon',
  //           route: 'misc-coming-soon',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Not Authorized',
  //           route: 'misc-not-authorized',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Under Maintenance',
  //           route: 'misc-under-maintenance',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Error',
  //           route: 'misc-error',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //   ],
  // },
]
